import * as React from 'react';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecList.css';
import SearchIcon from '../../assets//image/common/icon_search_keyword_blue.svg';
import FlowArea from '../flow/FlowArea';
import QEXP from '../../common/QuestionExp.json';
import QuestionPopper from '../popper/QuestionPopper';
import InformationMessage from '../message/InformationMessage';
import ConfirmMessage from '../message/ConfirmMessage';
import { useProgress } from '../../provider/progress/ProgressProvider';
import FooterContent from '../footer/Footer';
import { chatOpen } from '../chat/ChatwootWidget';

export default function ModelNumberList() {
  const { setAlertShow, setMessageList } = useAlert();
  const { setProgressShow } = useProgress();
  const navigate = useNavigate();
  const location = useLocation();
  enum SortByItems {
    ASC = 'ASC',
    DESC = 'DESC',
  }
  enum SortItems {
    CHECK_BOX = 'checkBox',
    FILE_NAME = 'fileName',
    LABEL_NAME = 'labelName',
    MODEL_NUMBER = 'modelNumber',
    MATERIAL_NAME = 'materialName',
    PRINTER_NAME = 'printerName',
    OPTION = 'option',
    PRICE = 'price',
    DATE = 'date',
    STATUS = 'status',
    UPDATE_DATE = 'updateDate',
  }
  enum FilterItems {
    ALL = 'all',
    FIXED = 'specFixed',
    PRICE_CHECK = 'checkingPrice',
    TEMP_SAVE = 'tempSaved',
  }
  const FILTER_ITEMS_STRINGS = {
    [FilterItems.ALL]: 'すべて',
    [FilterItems.FIXED]: '仕様確定',
    [FilterItems.PRICE_CHECK]: '単価設定担当者確認',
    [FilterItems.TEMP_SAVE]: '一時保存中',
  };
  const HEADER_ITEMS_STRINGS = {
    [SortItems.CHECK_BOX]: '',
    [SortItems.FILE_NAME]: 'ファイル名',
    [SortItems.LABEL_NAME]: 'ラベル名',
    [SortItems.MODEL_NUMBER]: '型番',
    [SortItems.MATERIAL_NAME]: '造形材料',
    [SortItems.PRINTER_NAME]: '機種',
    [SortItems.OPTION]: '応力除去',
    [SortItems.PRICE]: '単価',
    [SortItems.DATE]: '標準納期',
    [SortItems.STATUS]: 'ステータス',
    [SortItems.UPDATE_DATE]: '更新日時',
  };

  // メイン画面に表示する型番仕様一覧
  const [modelNumberList, setModelNumberList] = React.useState<MyIF.modelNumberList[]>([]);
  // どのステータスでフィルターをかけているか
  const [filterItem, setFilterItem] = React.useState<string>(FilterItems.ALL);
  // 各ステータス毎の型番仕様一覧のHit数を格納
  const [tableFilter, setTableFilter] = React.useState<{ [name: string]: string }>({});
  // どのアイテムで降順、昇順をリクエストしたか記録しておく。
  const [sorting, setSorting] = React.useState<string>(SortItems.MODEL_NUMBER);
  // ソート順
  const [sortBy, setSortBy] = React.useState<string>('DESC');
  // 型番仕様一覧の総ページ数
  const [totalPageNum, setTotalPageNum] = React.useState<number>(1);
  // 表示しているページ番号
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  // 検索しているキーワード
  const [searchKeyword, setSearchKeyword] = React.useState<string>();
  // 検索入力フォーム
  const searchKeywordInputRef = React.useRef<HTMLInputElement>(null!);
  // 1ページに表示するアイテムの数
  const perPage = 7;
  // チェックボックス用のState
  const [checkedItems, setCheckedItems] = React.useState<{ [modelNumber: string]: boolean }>({});
  const checkBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.id]: event.target.checked,
    });
  };
  // 初期モーダル制御
  const [isInitMessage, setIsInitMessage] = React.useState(true);
  const handleInitClose = () => {
    setIsInitMessage(false);
  };
  // 削除モーダル制御
  const [isDelMessage, setIsDelMessage] = React.useState(false);
  const handleDelCancel = () => {
    setIsDelMessage(false);
  };
  const handleDelOK = () => {
    deleteModelNumber();
    setIsDelMessage(false);
  };

  /**
   * 型番仕様一覧取得API呼び出し
   */
  const GetModelNumberList = React.useCallback(async () => {
    setModelNumberList([]);

    // API呼び出し
    const result = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        navigate: navigate,
      })
    ).GetModelNumberList(
      sorting,
      sortBy,
      currentPage,
      filterItem,
      searchKeyword,
      Object.keys(checkedItems).filter((e) => checkedItems[e] === true)
    );
    if (result !== null) {
      setModelNumberList(result.modelNumberList);

      let tmpFilter: {
        [name: string]: string;
      } = {
        [FilterItems.ALL]: String(result.filterCount.all),
        [FilterItems.FIXED]: String(result.filterCount.specFixed),
        [FilterItems.PRICE_CHECK]: String(result.filterCount.checkingPrice),
        [FilterItems.TEMP_SAVE]: String(result.filterCount.tempSaved),
      };
      setTableFilter(tmpFilter);

      // フィルターによって総件数が変わる
      let allCount = Number(tmpFilter[filterItem]);
      setTotalPageNum(Math.ceil(allCount / perPage) === 0 ? 1 : Math.ceil(allCount / perPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sorting,
    sortBy,
    currentPage,
    filterItem,
    searchKeyword,
    FilterItems.ALL,
    FilterItems.FIXED,
    FilterItems.PRICE_CHECK,
    FilterItems.TEMP_SAVE,
  ]);

  /**
   * 型番削除API呼び出し
   */
  const deleteModelNumber = async () => {
    setProgressShow(true);
    const checkResult = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        setAlertMessage: setMessageList,
        navigate: navigate,
      })
    ).DeleteModelNumber(Object.keys(checkedItems).filter((e) => checkedItems[e] === true));
    if (checkResult) {
      if (checkResult.length > 0) {
        setMessageList(checkResult);
        setAlertShow(true);
      } else {
        setCheckedItems({});
        await GetModelNumberList();
      }
    }
    setProgressShow(false);
  };

  // ソート反転
  const sortItems = (sortItem: string) => {
    if (sortItem === sorting) {
      // 直前にソートした項目であれば逆転
      if (sortBy === SortByItems.DESC) {
        setSortBy(SortByItems.ASC);
      } else {
        setSortBy(SortByItems.DESC);
      }
    } else {
      // 初めてソートする項目であれば、降順にする
      setSortBy(SortByItems.DESC);
    }
    setSorting(sortItem);
    setCurrentPage(1);
  };

  // 型番詳細画面に遷移
  const navigateModelNumberEdit = (modelNumber: string, labelName: string, readOnly: boolean) => {
    if (readOnly) {
      navigate('/ModelNumberRef', {
        state: {
          referrer: location.pathname,
          modelNumber: modelNumber,
          labelName: labelName,
        },
      });
    } else {
      navigate('/ModelNumberEdit', {
        state: {
          referrer: location.pathname,
          modelNumber: modelNumber,
          labelName: labelName,
        },
      });
    }
  };

  const startChatSupport = () => {
    chatOpen();
  };

  const navigateTrialCalcEdit = async () => {
    // 変な型番が選ばれていないかチェック
    const result = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        setAlertMessage: setMessageList,
        navigate: navigate,
      })
    ).GetModelNumberCheck(Object.keys(checkedItems).filter((e) => checkedItems[e] === true));
    if (result != null) {
      const checkResult: string[] = result.data.checkResult;
      if (checkResult.length === 0) {
        navigate('/TrialCalcEdit', {
          state: {
            referrer: location.pathname,
            trialNumber: '',
            labelName: '',
            modelNumberList: Object.keys(checkedItems).filter((e) => checkedItems[e] === true),
          },
        });
      } else {
        setMessageList(checkResult);
        setAlertShow(true);
      }
    }
  };

  /**
   * 初回呼び出し
   */
  React.useEffect(() => {
    GetModelNumberList();
  }, [GetModelNumberList]);

  React.useEffect(() => {
    // フィルターを変更したときは、選択しているものをリセット
    setCheckedItems({});
    setCurrentPage(1);
  }, [filterItem]);

  function resetCaretPosition() {
    const container = document.createElement('div'); // 編集不可の要素を作成
    container.setAttribute('contenteditable', 'true'); // 編集不可の要素にcontenteditable属性を設定
    container.style.opacity = '0'; // 編集不可の要素を非表示にする
    container.style.position = 'fixed'; // 編集不可の要素を画面外に配置する
    container.style.pointerEvents = 'none'; // 編集不可の要素をクリック可能にするためにpointer-eventsをnoneにする
    container.textContent = '\u200b'; // 編集不可の要素に空白を設定する
    document.body.appendChild(container); // 編集不可の要素をdocumentに追加する

    container.focus(); // 編集不可の要素にフォーカスを移動する
    window.getSelection()?.removeAllRanges(); // 選択範囲を全て解除する

    // 編集不可の要素を非表示にしてから表示することで、キャレット位置のリセットを実現する
    container.style.display = 'none';
    container.style.display = '';

    container.blur(); // 編集不可の要素からフォーカスを外す
    document.body.removeChild(container); // 編集不可の要素をdocumentから削除する
  }

  React.useEffect(() => {
    // resetCaretPosition関数を使用して、キャレット位置をリセットする
    resetCaretPosition();
  }, []);

  return (
    <div id='MainBody'>
      <FlowArea active={1}></FlowArea>
      <div className='mainContentsBlock'>
        <div className='pageTitleBlock'>
          <div className='pageTitleInner'>
            <div className='pageTitleWithBtn'>
              <h1 className='pageTitle'>単価の試算一覧</h1>
              <button
                style={{ marginLeft: '20px' }}
                className='btnCirclePlusOrange'
                onClick={() => navigateModelNumberEdit('', '', false)}
              >
                単価の試算
              </button>
              <button className='btnChatWhite' onClick={startChatSupport}>
                3D CADデータがない場合
              </button>
            </div>
            <div className='searchInputBlock'>
              <span style={{ marginRight: '10px' }}>
                <QuestionPopper value={QEXP.ModelNumberList.SEARCH} />
              </span>

              <input
                ref={searchKeywordInputRef}
                type='search'
                className='searchfKeywordInput'
                role='search'
                placeholder='検索キーワードを入力'
                maxLength={50}
                name=''
                autoComplete='off'
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    setSearchKeyword(event.currentTarget.value);
                    setCurrentPage(1);
                  }
                }}
              />
              <button
                type='button'
                name='button'
                className='searchfKeywordSubmit'
                onClick={() => {
                  setSearchKeyword(searchKeywordInputRef.current.value);
                  setCurrentPage(1);
                }}
              >
                <img src={SearchIcon} alt='' />
              </button>
            </div>
          </div>
        </div>
        <div className='modelNumberListControlBlock'>
          {/* ステータスによってテーブルを絞り込む */}
          <div className='modelNumberListControlInner'>
            <ul className='modelNumberListNarrowingList'>
              {Object.entries(FILTER_ITEMS_STRINGS).map(([key, value]) => {
                return (
                  <li key={key} className='modelNumberListNarrowingListItem'>
                    <button
                      className='modelNumberListNarrowingListLink button-style-trans'
                      onClick={() => setFilterItem(key)}
                    >
                      <span
                        className={`${
                          key === filterItem ? 'modelNumberListNarrowingListItemActive' : null
                        } `}
                      >
                        {value}
                      </span>
                      <span className='modelNumberListNarrowingListItemCount'>
                        ({tableFilter[key]})
                      </span>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* ページング */}
          <div className='modelNumberListPagingInner'>
            <div className='modelNumberListPagingBlock'>
              <p className='modelNumberListPagingPara'>全{tableFilter[filterItem]}件</p>
              <button
                className={
                  currentPage === 1
                    ? 'modelNumberListPagingLinkPrevDisabled button-style'
                    : 'modelNumberListPagingLinkPrevActive button-style'
                }
                onClick={() => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
                disabled={currentPage === 1 ? true : false}
              ></button>
              <p className='modelNumberListPagingCount'>
                <span className='modelNumberListPagingCountDisplay'>{currentPage}</span> /{' '}
                {totalPageNum}ページ
              </p>
              <button
                className={
                  currentPage === totalPageNum
                    ? 'modelNumberListPagingLinkNextDisabled button-style'
                    : 'modelNumberListPagingLinkNextActive button-style'
                }
                onClick={() => {
                  if (totalPageNum > currentPage) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
                disabled={currentPage === totalPageNum ? true : false}
              ></button>
            </div>
          </div>
        </div>

        {/* 型番仕様一覧テーブル */}
        <div className='modelNumberListTableBlock'>
          <table className='modelNumberListTable'>
            <thead>
              <tr>
                {Object.entries(HEADER_ITEMS_STRINGS).map(([key, value]) => {
                  return (
                    <th key={key}>
                      {value}
                      <button
                        className={`${
                          sorting === key
                            ? sortBy === SortByItems.DESC
                              ? 'modelNumberListSortLink modelNumberListSortLinkDescending button-style'
                              : 'modelNumberListSortLink modelNumberListSortLinkAscending button-style'
                            : 'modelNumberListSortLink modelNumberListSortLinkDefalt button-style'
                        } `}
                        onClick={() => sortItems(key)}
                      ></button>
                    </th>
                  );
                })}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {modelNumberList.map((modelNumber: MyIF.modelNumberList, i: number) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className='modelNumberListTableItemThumb'>
                        <div className='customCheckBox'>
                          <input
                            id={modelNumber.modelNumber}
                            type='checkbox'
                            name='checkbox'
                            value={modelNumber.modelNumber}
                            className={`modelNumberListTableItemThumbCheckbox ${
                              modelNumber.status === FILTER_ITEMS_STRINGS[FilterItems.FIXED]
                                ? 'Orange'
                                : 'Blue'
                            }`}
                            checked={
                              checkedItems[modelNumber.modelNumber]
                                ? checkedItems[modelNumber.modelNumber]
                                : false
                            }
                            onChange={(event) => {
                              checkBoxChange(event);
                            }}
                          />
                        </div>
                        <img
                          src={modelNumber.image}
                          alt=''
                          className='modelNumberListTableItemThumbImage'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemFilename'>{modelNumber.fileName}</div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemLabelname'>
                        {modelNumber.labelName}
                      </div>
                    </td>
                    <td>
                      <div
                        className='modelNumberListTableItemModelname'
                        style={{ wordBreak: 'normal', overflowWrap: 'normal' }}
                      >
                        {modelNumber.modelNumber}
                      </div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemMaterial'>
                        {modelNumber.materialName}
                      </div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemMachine'>{modelNumber.modelName}</div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemMachine'>
                        {modelNumber.optionStressRelief}
                      </div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemPrice'>
                        ￥{modelNumber.price ? modelNumber.price.toLocaleString() : '-'}
                      </div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemDelivery'>{modelNumber.date}</div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemStatus'>{modelNumber.status}</div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemDate'>{modelNumber.updateDate}</div>
                    </td>
                    <td>
                      <div className='modelNumberListTableItemToDetail'>
                        <button
                          className='modelNumberListTableItemCheckbtn'
                          onClick={() => {
                            const readOnly =
                              FILTER_ITEMS_STRINGS[FilterItems.TEMP_SAVE] === modelNumber.status
                                ? false
                                : true;
                            navigateModelNumberEdit(
                              modelNumber.modelNumber,
                              modelNumber.labelName,
                              readOnly
                            );
                          }}
                        >
                          {FILTER_ITEMS_STRINGS[FilterItems.TEMP_SAVE] === modelNumber.status
                            ? '編集'
                            : '参照'}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {modelNumberList?.length > 0 && (<button
            className={`btnOrangeCommit btnOrangeCommitBig btnOrangeCommitTable ${
              Object.values(checkedItems).filter((e) => e === true).length === 0 ? '' : 'btnOrangeCommitBigHoverEnable'
            }`}
            type='button'
            name='button'
            onClick={navigateTrialCalcEdit}
            disabled={
              Object.values(checkedItems).filter((e) => e === true).length === 0 ? true : false
            }
          >
            □をチェックして、<br />
            次へ進む
          </button>)}
        </div>
        <div className='fixedListControlBlock'>
          <div className='fixedListControlInner'>
            <p className='fixedListControlSelectCountPara'>
              選択中：<span>{Object.values(checkedItems).filter((e) => e === true).length}</span>件
            </p>
            <button
              className={`btnOrangeCommit btnOrangeCommitBig ${
                Object.values(checkedItems).filter((e) => e === true).length === 0 ? '' : 'btnOrangeCommitBigHoverEnable'
              }`}
              type='button'
              name='button'
              onClick={navigateTrialCalcEdit}
              disabled={
                Object.values(checkedItems).filter((e) => e === true).length === 0 ? true : false
              }
            >
              □をチェックして、<br />
              次へ進む
            </button>
            <button
              className='btnGrayDelete'
              type='button'
              name='button'
              onClick={() => {
                setIsDelMessage(true);
              }}
              disabled={
                Object.values(checkedItems).filter((e) => e === true).length === 0 ? true : false
              }
            >
              削除
            </button>
          </div>
          <FooterContent />
        </div>
      </div>

      <InformationMessage
        show={isInitMessage}
        title={'操作説明'}
        message={''}
        imagePath={'ModelNumberList.png'}
        handleClose={handleInitClose}
      ></InformationMessage>

      <ConfirmMessage
        show={isDelMessage}
        title={'注意'}
        message={'削除します。よろしいですか？'}
        handleOK={handleDelOK}
        handleCancel={handleDelCancel}
      ></ConfirmMessage>
    </div>
  );
}
