import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/top.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
interface Props {
  newsRef: React.MutableRefObject<HTMLDivElement>;
  releaseInfoList: MyIF.releaseInfo[];
}

const News: React.FC<Props> = React.memo(({ newsRef, releaseInfoList }) => {
  const [selectTab, setSelectTab] = React.useState<MyIF.releaseInfoType>('インフォメーション');

  return (
    <div id='news' ref={newsRef}>
      <div className='backgroundTitleText03'>
        <span>News</span>
      </div>
      <div className='newsBlock'>
        <h2>お知らせ</h2>
        <div className='newsContents'>
          <div className='categoryTabArea'>
            <button
              className='pressReleaseTab button-style'
              onClick={() => {
                setSelectTab('プレスリリース');
              }}
            >
              プレスリリース
            </button>
            <button
              className='informationTab button-style'
              onClick={() => {
                setSelectTab('インフォメーション');
              }}
            >
              インフォメーション
            </button>
          </div>
          <section className='newsAccordion'>
            <div className='newsAccordionBlock'>
              {releaseInfoList?.map((releaseInfo: MyIF.releaseInfo, index) => {
                return (
                  selectTab === releaseInfo.type && (
                    <div key={index}>
                      <input id={'pressReleaseBlock-' + index} type='checkbox' className='toggle' />
                      <label className='newsLabel' htmlFor={'pressReleaseBlock-' + index}>
                        <p className='articleDate'>
                          {releaseInfo.date}
                          <span
                            className={`${
                              releaseInfo.type === 'インフォメーション'
                                ? 'newsCategoryLabelInformation'
                                : 'newsCategoryLabel'
                            }`}
                          >
                            {releaseInfo.type}
                          </span>
                        </p>
                        <h3>{releaseInfo.title}</h3>
                      </label>
                      <div className='newsContent'>
                        <p>{releaseInfo.content}</p>

                        {releaseInfo.link && (
                          <a href={releaseInfo.link} target='_blank' rel='noopener noreferrer'>
                            {releaseInfo.link}
                          </a>
                        )}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
});

export default News;
