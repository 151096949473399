import * as React from 'react';
import * as MyIF from '../common/Interface';
import { APIController } from '../common/API';
import { useAlert } from '../provider/alert/AlertProvider';
import HeaderNoLogin from '../components/header/HeaderNoLogin';
import About3DFABs from '../components/topPage/Abount3DFABs';
import FAQ from '../components/topPage/FAQ';
import FirstViewContents from '../components/topPage/FirstViewContents';
import Information from '../components/topPage/Information';
import LinkArea from '../components/topPage/LinkArea';
import News from '../components/topPage/News';
import FooterContent from '../components/footer/Footer';
import NoticeArea from '../components/topPage/NoticeArea';

const useTopPage = () => {
  const { setAlertShow } = useAlert();

  const aboutRef = React.useRef<HTMLDivElement>(null!);
  const aboutRef2 = React.useRef<HTMLDivElement>(null!);
  const aboutRef3 = React.useRef<HTMLDivElement>(null!);
  const newsRef = React.useRef<HTMLDivElement>(null!);

  // インフォメーション情報
  const [releaseInfoList, setReleaseInfoList] = React.useState<MyIF.releaseInfo[]>([]);

  React.useEffect(() => {
    document.body.style.minWidth = 'auto';
    document.body.style.overflowX = 'hidden';
    return () => {
      document.body.style.minWidth = '1024px';
      document.body.style.overflowX = 'auto';
    };
  }, []);

  React.useEffect(() => {
    (async () => {
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
        })
      ).GetReleaseInfo();
      if (result !== null) {
        setReleaseInfoList(result);
      }
    })();
  }, []);

  return (
    <div style={{ display: 'flex', flexFlow: 'column', minHeight: '100vh' }}>
      <div style={{ flex: '1' }}>
        <div className='topMainContentsBlock'>
          <HeaderNoLogin></HeaderNoLogin>
          <Information newsRef={newsRef} releaseInfoList={releaseInfoList} />
          <FirstViewContents
            aboutRef={aboutRef}
            aboutRef2={aboutRef2}
            aboutRef3={aboutRef3}
          />
          <About3DFABs aboutRef={aboutRef} aboutRef2={aboutRef2} aboutRef3={aboutRef3}/>
          <FAQ />
          <News newsRef={newsRef} releaseInfoList={releaseInfoList} />
          <LinkArea />
          <NoticeArea />
        </div>
      </div>
      <FooterContent noUI={true} topPage={true}></FooterContent>
    </div>
  );
};

export default useTopPage;
